import React, { ReactElement, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import classNames from 'classnames'

import Slider from 'components/Slider'
import { Footer } from 'components/Layout'
import CookieConsentDialog from 'components/CookieConsentDialog'

import { useGenericPages } from 'hooks'

import logo from 'assets/life-science-shield-logo.svg'
import man1 from 'assets/man-1.jpg'
import woman1 from 'assets/woman-1.jpg'
import man2 from 'assets/man-2.jpg'
import woman2 from 'assets/woman-2.jpg'
import man3 from 'assets/man-3.jpg'
import signInIcon from 'assets/sign-in-icon.svg'

import 'styles/index.scss'

const PHOTOS = [man1, woman1, man2, woman2, man3]

export default function LandingPage(): ReactElement {
  const [menuIsVisible, setMenuVisibility] = useState<boolean>(false)
  const { financePages, aboutPage } = useGenericPages()

  return (
    <main className="landing-page">
      <Helmet>
        <title>Jevitty</title>
      </Helmet>

      <div className="landing-page__content">
        <section className="landing-page__section">
          <h1 className="landing-page__title">
            <img
              className="landing-page__logo"
              src={logo}
              alt="Jevitty Life Science"
            />
          </h1>
        </section>

        <section className="landing-page__section">
          <h3 className="landing-page__section__title">
            What if you could unlock the secret of living for 200&nbsp;years?
          </h3>

          <p className="landing-page__paragraph">
            Pushing the limits of the improved human lifespan becomes possible
            when knowledge meets&nbsp;technology.
          </p>

          <p className="landing-page__paragraph">
            Jevitty is a new way of looking at how we live in order to create an
            optimal balance of body and&nbsp;mind. Together&nbsp;we can
            transform what human longevity means, and help redefine your
            true&nbsp;potential.
          </p>

          <p className="landing-page__paragraph">
            Through community, education and&nbsp;analysis, Jevitty&nbsp;is
            dedicated to unlocking the key to quality longevity for
            its&nbsp;users.
          </p>

          <a className="btn btn-primary" href="https://www.jevittyapp.com/">
            Download Jevitty
          </a>
        </section>

        <Footer />
      </div>

      <div className="landing-page__photos">
        <Slider photos={PHOTOS} />
      </div>

      <nav
        className={classNames('landing-page__menu', {
          'landing-page__menu--visible': menuIsVisible,
        })}
      >
        <button
          className={classNames('landing-page__menu__toggle', {
            'landing-page__menu__toggle--close': menuIsVisible,
            'landing-page__menu__toggle--open': !menuIsVisible,
          })}
          onClick={() => setMenuVisibility(!menuIsVisible)}
        >
          <span className="landing-page__menu__toggle__text">
            {menuIsVisible ? 'Close' : 'Menu'}
          </span>
        </button>

        <ul className="landing-page__menu__links">
          {aboutPage && (
            <li>
              <Link
                className="landing-page__menu__link"
                to={`/${aboutPage.slug}/`}
              >
                {aboutPage.title}
              </Link>
            </li>
          )}
          {/* reference link to web.jevitty.com */}
          <li>
            <a
              className="landing-page__menu__link"
              href="https://www.jevittyapp.com/"
            >
              Download Jevitty
            </a>
          </li>
          <li>
            <a className="landing-page__menu__link" href="https://www.bodycomp.ca/">
              Bodycomp Imaging
            </a>
          </li>
        </ul>

        {financePages && (
          <ul className="landing-page__menu__links">
            {financePages.map(({ slug, title }) => (
              <li key={slug}>
                <Link className="landing-page__menu__link" to={`/${slug}/`}>
                  {title}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </nav>

      <CookieConsentDialog />
    </main>
  )
}
